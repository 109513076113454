.HomeSearchContainer
   height: 140px
   display: flex

.HomeSearchBar
   display: -webkit-box
   display: -ms-flexbox
   display: flex
   -webkit-box-pack: center
   -ms-flex-pack: center
   justify-content: center
   -webkit-box-align: center
   -ms-flex-align: center
   align-items: center
   margin: auto
   z-index: 2
   width: 80%
   max-width: $HzMaxWidth
   font-size: 0.85rem

   .HomeInput
      display: -ms-grid
      display: grid
      width: 100%
      min-height: 45px
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center
      border-bottom: 2px solid $SGC
      background: white
      -ms-grid-columns: 27% 73%
      grid-template-columns: 27% 73%

      .HomeSearchOption
         display: -webkit-box
         display: -ms-flexbox
         display: flex
         height: 100%
         position: relative
         .SearchOptionText, .SearchOptionIcon
            display: -webkit-box
            display: -ms-flexbox
            display: flex
            -webkit-box-align: center
            -ms-flex-align: center
            align-items: center

         .SearchOptionText
            flex-grow: 1
            padding: 10px 0 10px 20px
            background: $Gray
            height: 100%
            text-wrap: nowrap
            overflow: hidden
            span
               overflow: hidden
               text-overflow: ellipsis
               color: $DarkGray
               font-weight: 600
               cursor: pointer
         
         .SearchOptionIcon
            border-radius: 0
            height: 100%
            background: $Gray
            svg
               color: $SGC
               &:hover
                  opacity: 0.7

         .SearchList
            position: absolute
            top: calc( 100% + 1.5px )
            width: 100%
            background: $Gray
            padding: 10px 10px 10px 21px
            .radio-group
               label.control-label
                  width: 100%
                  transition: .25s ease
                  &:hover
                     opacity: .8
               span.MuiFormControlLabel-label
                  font: inherit
                  color: $DarkGray
                  font-weight: 600
               .MuiRadio-root, .Mui-checked
                  color: $SGC

      .HomeInputBar
         display: -webkit-box
         display: -ms-flexbox
         display: flex
         height: 100%

         .SearchInput
            display: -webkit-box
            display: -ms-flexbox
            display: flex
            width: 100%
            height: 100%
            border-top: 2px solid $Gray
            .SearchInputText
               font-family: inherit
               width: 100%
               padding-left: 1rem

         .SearchButton
            height: 100%
            padding: 7px
            border-radius: 0
            background: $SGC
            display: flex
            img
               min-width: 2rem
               transition: .25s ease
            &:hover
               img
                  transform: scale(95%)

@media ( max-width: $Medium )
   .HomeSearchBar
      width: 90%
      .HomeInput
         -ms-grid-columns: 30% 70%
         grid-template-columns: 30% 70%
         
@media ( max-width: $Small )
   .HomeSearchContainer
      height: 120px
   .HomeSearchBar
      .HomeInput
         -ms-grid-columns: auto 1fr
         grid-template-columns: auto 1fr
         .HomeSearchOption
            .SearchOptionText
               display: none
            .SearchList
               width: max-content

